<template>
    <main id="liste">
        <HeaderTab 
            :title="$t('invoice.mes_factures')" 
            :swiper_tabs="swiperTabs" 
            :swiper_action="change_tab"    
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <h2>{{ $t('invoice.titre_a_payer') }}</h2>
                            <div v-if="enable_add_mandate">
                                {{ $t('gocardless.add_mandate') }}<br>
                                <button :disabled="loading_add_mandate" class="btn btn-primary" type="button" @click.prevent="add_mandate()">
                                    <font-awesome-icon v-if="loading_add_mandate" :icon="['far', 'spinner']" spin />
                                    {{ $t('global.ajouter') }}
                                </button>
                            </div>
                            <div class="alert alert-info" v-if="items_to_pay.length == 0 && actual_tab == 'a_payer' && working == false">
                                {{ $t('invoice.no_facture_to_pay') }}
                            </div>
                            <CustomTable
                                id_table="addressed_invoices_to_pay"
                                ref="addressed_invoices_to_pay"
                                :items="items_to_pay"
                                :busy.sync="working"
                                :sortDesc="false"
                                sortBy="duedate_date"
                                primaryKey="duedate_id"
                                :externSlotColumns="extern_slot_columns"
                                :hasExportOption="false"
                                :showPagination="false"
                            >
                                <template v-slot:custom-slot-cell(prelevement)="{ data }">
                                    <span v-if="data.prelevement == true">{{ $t('global.oui') }}</span>
                                    <span v-else>{{ $t('global.non') }}</span>
                                </template>
                            </CustomTable>
                        </div>

                        <div class="box">
                            <h2>{{ $t('invoice.titre_soldees') }}</h2>
                            <CustomTable
                                id_table="addressed_invoices_sold"
                                :items="items_sold"
                                :busy.sync="working"
                                :sortDesc="false"
                                sortBy="duedate_date"
                                primaryKey="duedate_id"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("action.online_paiement") }}
            </template>

            <div v-if="!modal_loaded">
                <LoadingSpinner class="col-12" />
            </div>

            <div v-if="modal_loaded">

                <template v-if="marketplace == 'mangopay'">
                    
                    <RequiredInfosUser 
                        v-if="require_infos"
                        :callback="prepareDuedate"/>

                    <form v-else @submit.prevent="payDuedates">

                        <div class="row">
                            <div class="col-12" v-if="form_message !== ''">
                                <ErrorAlert :messageI18n="form_message" />
                            </div>

                            <div class="col-12">
                                <h3>{{ $t('marketplace.amount_to_pay') }}</h3>
                                <template v-for="(date, key) in dates">
                                    <div :key="key">
                                        <p v-if="key == current_date.toISOString()">{{ $t('marketplace.au_plus_vite') }} : {{ date }} €</p>
                                        <p v-else>{{ key }} : {{ date }} €</p>
                                    </div>
                                </template>
                            </div>

                            <div class="col-12">
                                <label>{{ $t('marketplace.choix_compte_bancaire') }}</label>
                                <e-select
                                    v-model="compte"
                                    track-by="id"
                                    label="formatted_libelle"
                                    :placeholder="getTrad('marketplace.choix_compte_bancaire')"
                                    :selectedLabel="getTrad('global.selected_label')"
                                    :options="formattedAccount"
                                    :allow-empty="true"
                                    :show-labels="false"
                                    :sortable="false"
                                    :loading="loadingAccount"
                                    :class="{ 'is-invalid': errors && errors.indexOf('account') > -1 }"
                                ></e-select>
                            </div>

                        </div>
                        <b-button class="mt-3" v-if="compte != null" block @click.prevent="payDuedates">{{ $t("marketplace.payer") }}</b-button>
                    </form>
                </template>
                <template v-else-if="marketplace == 'monetico'">
                    <form @submit.prevent="payDuedates">

                        <div class="row">
                            <div class="col-12" v-if="form_message !== ''">
                                <ErrorAlert :messageI18n="form_message" />
                            </div>

                            <div class="col-12">
                                <h3>{{ $t('marketplace.amount_to_pay') }}</h3>
                                <template v-for="(date, key) in dates">
                                    <div :key="key">
                                        <p v-if="key == current_date.toISOString()">{{ $t('marketplace.au_plus_vite') }} : {{ date }} €</p>
                                        <p v-else>{{ key }} : {{ date }} €</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <b-button class="mt-3" block @click.prevent="payDuedates">{{ $t("marketplace.payer") }}</b-button>
                    </form>

                    <form method="POST" :action="monetico_infos.url" id="redirectForm" class="d-none">
                        <input type="hidden" name="version" :value="monetico_infos.version">
                        <input type="hidden" name="TPE" :value="monetico_infos.TPE">
                        <input type="hidden" name="date" :value="monetico_infos.date">
                        <input type="hidden" name="montant" :value="monetico_infos.montant">
                        <input type="hidden" name="reference" :value="monetico_infos.reference">
                        <input type="hidden" name="MAC" :value="monetico_infos.MAC">
                        <input type="hidden" name="url_retour" :value="monetico_infos.url_retour">
                        <input type="hidden" name="url_retour_ok" :value="monetico_infos.url_retour_ok">
                        <input type="hidden" name="url_retour_err" :value="monetico_infos.url_retour_err">
                        <input type="hidden" name="lgue" :value="monetico_infos.lgue">
                        <input type="hidden" name="societe" :value="monetico_infos.societe">
                        <input type="hidden" name="texte-libre" :value="monetico_infos.textelibre">
                        <input type="hidden" name="mail" :value="monetico_infos.mail">
                        <button id="button_go_monetico" onclick="">valider</button>
                    </form>



                </template>
                <template v-else-if="marketplace == 'atos'">
                    <form @submit.prevent="payDuedates">

                        <div class="row">
                            <div class="col-12" v-if="form_message !== ''">
                                <ErrorAlert :messageI18n="form_message" />
                            </div>

                            <div class="col-12">
                                <h3>{{ $t('marketplace.amount_to_pay') }}</h3>
                                <template v-for="(date, key) in dates">
                                    <div :key="key">
                                        <p v-if="key == current_date.toISOString()">{{ $t('marketplace.au_plus_vite') }} : {{ date }} €</p>
                                        <p v-else>{{ key }} : {{ date }} €</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <b-button class="mt-3" block @click.prevent="payDuedates">{{ $t("marketplace.payer") }}</b-button>
                    </form>

                    <!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
                    <form method="post" :action="atos_infos.url" id="redirectForm" class="d-none">
                        <input type="hidden" name="Data" :value="atos_infos.data">
                        <input type="hidden" name="InterfaceVersion" value="HP_2.14">
                        <input type="hidden" name="Seal" :value="atos_infos.seal">
                        <button id="button_go_atos" onclick="">valider</button>
                    </form>
                </template>
                <template v-else-if="marketplace == 'paybox'">
                    <form @submit.prevent="payDuedates">

                        <div class="row">
                            <div class="col-12" v-if="form_message !== ''">
                                <ErrorAlert :messageI18n="form_message" />
                            </div>

                            <div class="col-12">
                                <h3>{{ $t('marketplace.amount_to_pay') }}</h3>
                                <template v-for="(date, key) in dates">
                                    <div :key="key">
                                        <p v-if="key == current_date.toISOString()">{{ $t('marketplace.au_plus_vite') }} : {{ date }} €</p>
                                        <p v-else>{{ key }} : {{ date }} €</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <b-button class="mt-3" block @click.prevent="payDuedates">{{ $t("marketplace.payer") }}</b-button>
                    </form>

                    <!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
                    <form method="POST" :action="paybox_infos.url" id="redirectForm" class="d-none">
                        <input type="hidden" name="PBX_SITE" :value="paybox_infos.PBX_SITE">
                        <input type="hidden" name="PBX_RANG" :value="paybox_infos.PBX_RANG">
                        <input type="hidden" name="PBX_IDENTIFIANT" :value="paybox_infos.PBX_IDENTIFIANT">
                        <input type="hidden" name="PBX_TOTAL" :value="paybox_infos.PBX_TOTAL">
                        <input type="hidden" name="PBX_DEVISE" :value="paybox_infos.PBX_DEVISE">
                        <input type="hidden" name="PBX_RUF1" value="GET">
                        <input type="hidden" name="PBX_CMD" :value="paybox_infos.PBX_CMD">
                        <input type="hidden" name="PBX_PORTEUR" :value="paybox_infos.PBX_PORTEUR">
                        <input type="hidden" name="PBX_REPONDRE_A" :value="paybox_infos.PBX_REPONDRE_A">
                        <input type="hidden" name="PBX_RETOUR" :value="paybox_infos.PBX_RETOUR">
                        <input type="hidden" name="PBX_EFFECTUE" :value="paybox_infos.PBX_EFFECTUE">
                        <input type="hidden" name="PBX_ANNULE" :value="paybox_infos.PBX_ANNULE">
                        <input type="hidden" name="PBX_REFUSE" :value="paybox_infos.PBX_REFUSE">
                        <input type="hidden" name="PBX_HASH" value="SHA512">
                        <input type="hidden" name="PBX_TIME" :value="paybox_infos.PBX_TIME">
                        <input type="hidden" name="PBX_SHOPPINGCART" :value="paybox_infos.PBX_SHOPPINGCART">
                        <input type="hidden" name="PBX_BILLING" :value="paybox_infos.PBX_BILLING">
                        <input type="hidden" name="PBX_HMAC" :value="paybox_infos.PBX_HMAC">
                        <button id="button_go_paybox" onclick="">valider</button>
                    </form>
                </template>

                <template v-if="marketplace == 'sogecommerce'">
                    <form @submit.prevent="payDuedates">

                        <b-button class="mt-3" block @click.prevent="payDuedates">{{ $t("marketplace.payer") }}</b-button>
                    </form>

                    <!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
                    <form method="POST" :action="sogecommerce_infos.url" id="redirectForm" class="d-none">
                        <input type="hidden" name="vads_action_mode" :value="sogecommerce_infos.vads_action_mode">
                        <input type="hidden" name="vads_amount" :value="sogecommerce_infos.vads_amount">
                        <input type="hidden" name="vads_ctx_mode" :value="sogecommerce_infos.vads_ctx_mode">
                        <input type="hidden" name="vads_currency" :value="sogecommerce_infos.vads_currency">
                        <input type="hidden" name="vads_page_action" :value="sogecommerce_infos.vads_page_action">
                        <input type="hidden" name="vads_payment_config" :value="sogecommerce_infos.vads_payment_config">
                        <input type="hidden" name="vads_site_id" :value="sogecommerce_infos.vads_site_id">
                        <input type="hidden" name="vads_trans_date" :value="sogecommerce_infos.vads_trans_date">
                        <input type="hidden" name="vads_trans_id" :value="sogecommerce_infos.vads_trans_id">
                        <input type="hidden" name="vads_version" :value="sogecommerce_infos.vads_version">
                        <input type="hidden" name="vads_payment_cards" :value="sogecommerce_infos.vads_payment_cards">
                        <input type="hidden" name="vads_order_id" :value="sogecommerce_infos.vads_order_id">
                        <input type="hidden" name="vads_cust_email" :value="sogecommerce_infos.vads_cust_email">
                        <input type="hidden" name="vads_cust_id" :value="sogecommerce_infos.vads_cust_id">
                        <input type="hidden" name="vads_cust_title" :value="sogecommerce_infos.vads_cust_title">
                        <input type="hidden" name="vads_cust_status" :value="sogecommerce_infos.vads_cust_status">
                        <input type="hidden" name="vads_cust_first_name" :value="sogecommerce_infos.vads_cust_first_name">
                        <input type="hidden" name="vads_cust_last_name" :value="sogecommerce_infos.vads_cust_last_name">
                        <input type="hidden" name="vads_cust_legal_name" :value="sogecommerce_infos.vads_cust_legal_name">
                        <input type="hidden" name="vads_cust_phone" :value="sogecommerce_infos.vads_cust_phone">
                        <input type="hidden" name="vads_cust_zip" :value="sogecommerce_infos.vads_cust_zip">
                        <input type="hidden" name="vads_cust_city" :value="sogecommerce_infos.vads_cust_city">
                        <input type="hidden" name="vads_cust_country" :value="sogecommerce_infos.vads_cust_country">
                        <input type="hidden" name="signature" :value="sogecommerce_infos.signature">
                        <button id="button_go_sogecommerce" onclick="">valider</button>
                    </form>
                </template>
            </div>
        </b-modal>

    </main>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Navigation from '@/mixins/Navigation.js'
    import Marketplace from '@/mixins/Marketplace.js'
    import Gocardless from '@/mixins/Gocardless.js'

	export default {
        name: "InvoiceAddressed",
        mixins:[Invoice, Navigation, Marketplace, Gocardless],
		data () {
			return {
                actual_tab: 'invoices',
                swiper_tabs: [{
                        id: 'invoices',
						label: 'menu.facturation.mes_factures',
						active: true
					}
                ],

                items_to_pay: [],
                url_boutique: null,
                marketplace: 'mangopay',
                items_sold: [],
                require_infos: true,
                working: false,
                modal_loaded: false,
                current_date: new Date(),
                form_message: '',
                errors: [],
                accounts: [],
                dates: {},
                go_atos: false,
                go_paybox: false,
                atos_infos: {},
                paybox_infos: {},
                sogecommerce_infos: {},
                monetico_infos: {},
                compte: null,
                events_tab: {
                    "TableAction::goToPdfInvoice": this.getAddressedPdfInvoice,
                    "TableAction::selectInvoiceToPayAndExec": this.checkAllInvoiceToPayAndExec,
                    "TableAction::payOnline": (variables) => {
                        const config = JSON.parse(this.getConfig("ca_config"))
                        this.duedates = variables
                        if(config.use_credit_invoice === false) {
                            //Je n'ai pas le droit d'utiliser les factures d'avoir pour payer mes factures 
                            this.duedates = this.duedates.filter(elem => {
                                if(elem.duedate_amount > 0) {
                                    return elem
                                }
                            })

                            if(this.duedates.length == 0) {
                                this.failureToast("toast.no_duedate_to_pay")
                                return false
                            }
                        }

                        this.$refs['modal'].show()
                        this.getMarketplaceTypeLocal()
                    }
                },
                extern_slot_columns: [
                    'prelevement'
                ],
                enable_add_mandate: false,
                loading_add_mandate: false,
                duedates: []
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                this.working = true

                if(this.$store.state.userAccess.hasPaymentGocardlessAccess) {
                    const bank_accounts = await this.getBankAccounts()
                    if(!bank_accounts) {
                        this.enable_add_mandate = true
                    }
                }

                const promises = [
                    this.load_to_pay(),
                    this.load_paid()
                ]

                Promise.all(promises)
                .catch(e => {
                    this.failureToast("toast.info_lecture_failed")
                    console.error("InvoiceAddressed::init_component => Error:", e)
                })
                .finally(() => {
                    this.working = false
                })
            },

            async load_to_pay() {
                return new Promise((res, rej) => {
                    this.getAddressedInvoicesToPay()
                    .then(r => {
                        this.items_to_pay = r
                        res()
                    })
                    .catch(e => {
                        rej(e)
                    })
                })
            },

            async load_paid() {
                return new Promise((res, rej) => {
                    this.getAddressedInvoicesSold()
                    .then(r => {
                        this.items_sold = r
                        res()
                    })
                    .catch(e => {
                        rej(e)
                    })
                }) 
            },

            change_tab(tab) {
                // On dévalide les autres tabs
                this.swiper_tabs.forEach(tab => {
                    tab.active = false
                })

                // On active la target
                tab.active = true

                // On change de tableau
                this.actual_tab = tab.id
            },

            async getMarketplaceTypeLocal(){

                let marketplace = await this.getMarketplaceType()
                this.marketplace = marketplace.retour.type
                if(this.marketplace == 'monetico')
                {
                    await this.prepareDuedate()
                }
                else if(this.marketplace == 'atos')
                {
                    await this.prepareDuedate()
                }
                else if(this.marketplace == 'paybox')
                {
                    await this.prepareDuedate()
                }
                this.modal_loaded = true
            },

            async prepareDuedate() {

                this.modal_loaded = true
                this.require_infos = false

                if(this.marketplace == 'mangopay')
                {
                    this.loadPaymentMethod()
                }
                else if(this.marketplace == 'monetico')
                {
                    //
                }
                else if(this.marketplace == 'atos')
                {
                    //
                }
                else if(this.marketplace == 'paybox')
                {
                    //
                }

                this.modal_loaded = false

                this.dates = {}
                for (let i = 0; i < this.duedates.length; i++)
                {

                    let date = new Date(this.duedates[i].duedate_date + " 00:00:00")

                    this.current_date.setHours(0)
                    this.current_date.setMinutes(0)
                    this.current_date.setSeconds(0)
                    let date_tab = date
                    if(date <= this.current_date)
                    {
                        date_tab = this.current_date
                    }

                    let balance = this.duedates[i].duedate_balance
                    balance = balance * 1

                    if(this.dates[date_tab.toISOString()])
                    {
                        this.dates[date_tab.toISOString()] += balance
                    }
                    else
                    {
                        this.dates[date_tab.toISOString()] = balance
                    }
                }

                this.modal_loaded = true
            },

            async payDuedates(){

                let duedates_id = this.duedates.map(duedate => {
                    return duedate.duedate_id
                })

                if(this.marketplace == 'mangopay')
                {
                    await this.markToPaymentDuedates(duedates_id, this.compte.id)
                    this.$refs['modal'].hide()
                    this.init_component()
                }
                else if(this.marketplace == 'monetico')
                {
                    let url_paiment = await this.redirectBoutique(duedates_id)
                    
                    if(url_paiment != null)
                    {
                        url_paiment.retour.url = url_paiment.retour.url.replace("texte-libre", "textelibre")
                        this.monetico_infos = this.URLToArray(url_paiment.retour.url)
                        this.monetico_infos.url = url_paiment.retour.url.substring(0, url_paiment.retour.url.indexOf('?'))

                        // A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
                        setTimeout(() => {
                            document.querySelector("#button_go_monetico").click()

                        },0)
                    }

                }
                else if(this.marketplace == 'atos')
                {
                    let datas_payment = await this.redirectBoutique(duedates_id)
                    if(datas_payment != null && datas_payment.retour.url != null)
                    {
                       
                        this.atos_infos = {
                            url: datas_payment.retour.url,
                            data: datas_payment.retour.data,
                            seal: datas_payment.retour.key,
                        }

                        //A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
                        setTimeout(() => {
                            document.querySelector("#button_go_atos").click()

                        },0)
                    }
                }
                else if(this.marketplace == 'paybox')
                {
                    let datas_payment = await this.redirectBoutique(duedates_id)

                    if(datas_payment != null && datas_payment.retour.url != null)
                    {
                        this.paybox_infos =  datas_payment.retour

                        //A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
                        setTimeout(() => {
                            document.querySelector("#button_go_paybox").click()
                        },0)
                    }
                }

                else if(this.marketplace == 'sogecommerce') {

                    let datas_payment = await this.redirectBoutique(duedates_id)

                    if(datas_payment != null && datas_payment.retour.url != null)
                    {
                        this.sogecommerce_infos =  datas_payment.retour
                        if(this.sogecommerce_infos.vads_amount == 0) {
                            this.failureToast("toast.contract_montant_zero")
                            this.$refs.modal.hide()
                            return false
                        }

                        //A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
                        setTimeout(() => {
                            document.querySelector("#button_go_sogecommerce").click()
                        },0)
                    }
                }
            },

            async loadPaymentMethod() {
                this.loadingAccount = true
                this.working = true
                
                this.getPaymentMethod(true)
                .then(r => {
                    this.accounts = r.retour
                    this.loadingAccount = false
                    this.working = false
                })
                .catch(e => {
                })
            },

            URLToArray(url) {
                var request = {};
                var pairs = url.substring(url.indexOf('?') + 1).split('&');
                for (var i = 0; i < pairs.length; i++) {
                    if(!pairs[i])
                        continue;
                    var pair = pairs[i].split('=');
                    request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
                }
                return request;
            },

            async checkAllInvoiceToPayAndExec() {
                this.$refs.addressed_invoices_to_pay.setCheckedAll(true)

                this.$refs['modal'].show()
                this.duedates = this.$refs.addressed_invoices_to_pay.items
                this.getMarketplaceTypeLocal()

            },

            async add_mandate() {
                this.loading_add_mandate = true
                const mandate = await this.addMandate()
                if(mandate) {
                    window.location = mandate.redirect_url
                } else {
                    this.loading_add_mandate = false
                }
            },
        },
        
        computed: {
            swiperTabs() {
                return this.swiper_tabs
            },
            formattedAccount(){
                let formattedAccount = []

                for (let i = 0; i < this.accounts.length; i++) {
                    let temp = this.accounts[i]

                    temp.formatted_libelle = this.accounts[i].libelle + " (" + this.accounts[i].numero.replace("<br>", ", ") + ")"

                    if(temp.mandate != null && (temp.mandate.status == "SUBMITTED" || temp.mandate.status == "ACTIVE" ))
                    {
                        formattedAccount.push(temp)
                    }
                }

                return formattedAccount
            }
        },
        watch: {
            formattedAccount(val){
                if(val.length == 1 && this.compte == null)
                {
                    this.compte = val[0]
                }
            }
        },

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            RequiredInfosUser : () => import('GroomyRoot/components/Marketplace/RequiredInfosUser'),
		}
	}
</script>
